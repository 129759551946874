export default {
  main: 'Home page',
  goToMain: 'To the main page',
  success: 'Success',
  error: 'Error',
  //General
  goBack: 'Back',
  continueNext: 'Continue',
  confirmation: 'Confirmation',
  confirm: 'Confirm',
  details: 'Details',
  cost: 'Cost',
  save: 'Save',
  close: 'Close',
  send: 'Send',
  extend: 'Extend',
  changePolicy: 'Change policy',
  cascoPriceLimit: 'The limit of the insurance payment is 100,000 tg.',
  cascoOnlyCars: 'Passenger cars only',
  phoneNumber: 'Phone number',
  enterPhoneNumber: 'Enter your phone number',
  incorrectPhoneNumber: 'Incorrect phone number',
  fullName: 'Full name',
  insurancePolicy: 'Insurance policy',
  halykIC: '"Halyk" IC',
  selectedPayment: 'Selected payment',
  insurance: 'Insurance',
  changeTariff: 'Change tariff',

  salary: 'Salary',
  agreementDate: 'Commencement date of the agreement',
  noAgreements: 'You haven\'t insured anything yet, but everything is still ahead!',
  policyStartDate: 'Policy start date',
  bonusProduct: 'Bonus product',
  upTo25percent: 'Up to 25% bonuses',
  agreedToInsuranceTerms: 'I agree with the {0} of Insurance and with the rates of "Halyk” “Insurance Company“ JSC.',
  readInsuranceTerms: 'I have read the insurance {0}',
  declaration: 'Declaration',
  declarationTitle: 'Declaration',
  rules: 'rules',
  apartmentInsurance: 'Apartment insurance',
  buyPolicy: 'Buy a policy',

  loyalClientModalTitle: 'Уважаемый клиент',
  loyalClientModalText: 'Вам предоставлена дополнительная страховка  к полису ОГПО ВТС! Мы ценим наших постоянных клиентов.',
  dearClient: 'Уважаемый клиент',
  visitBranchToSign: 'Для переоформления договора необходимо обратиться в офис страховой компании',

  cantChangeNumber: 'Нет возможности поменять номер телефона. Для замены необходимо обратится в службу поддержки клиентов, по номеру - 797',

  //Main menu
  menu: 'Menu',
  products: 'Products',
  insuredCase: 'Insured case',
  aboutUs: 'About Us',
  specialOffers: 'Special offers',
  language: 'Language',
  pay: 'Pay',

  //Agreements tab
  agreements: 'Agreements',
  effectiveAgreements: 'Effective',
  expiredAgreements: 'Expired',
  myGifts: 'My gifts',
  myMedCards: 'Мед карты',

  medInsureds: 'Insured',
  medCenterAddresses: 'Addresses of medical institutions',
  medPrograms: 'Medical Program',

  //Chat tab
  chat: 'Chat',
  writeToWhatsapp: 'Write through WhatsApp',
  callUs: 'Call us',
  chatUs: 'Write to us (chatbot)',
  getAPolicy: 'Get a policy',
  leaveFeedback: 'Leave feedback',
  support: 'Support',

  //Payment tab
  paymentFailed: 'Payment failed',
  paymentSuccess: 'Payment accepted',
  paymentReceipt: 'Payment receipt and payment status are available in history',

  //About us tab
  halykTextBold: ' "Halyk" Insurance Company"',
  halykText: ' is a universal insurance company that provides a full range of insurance services for all categories of legal entities and individuals. It is part of "Halyk Group" which is the largest financial group in Kazakhstan. The Company operates in the insurance market for 25 years.',
  address: 'Address',
  phones: 'Phones',
  schedule: 'Working schedule',
  goToHistory: 'Go to history',
  showOnMap: 'Show on map',
  branches: ['branch', 'branches', 'branches'],
  listOfBranches: 'List of branches',


  //Credit card
  cardNumber: 'Card number',
  cardDate: 'MM/YY',
  payButton: 'Pay',

  //Languages tab
  langRus: 'Russian',
  langKaz: 'Kazakh',
  langEng: 'English',

  logout: 'Logout',

  //Notifications tab
  notifications: 'Notifications',
  policyEnd: 'End of policy',
  specialOffer: 'Special offer',
  kaskoLightOffer: 'for KASKO Light',
  markAllAsRead: 'Mark all as read',

  //Feedback tab
  feedback: 'Feedback',
  pleaseRateService: 'Please rate the quality of our service:',
  pleaseRateBonuses: 'Please rate our bonuses:',
  pleaseRateManager: 'Please rate the work of the manager (if any):',
  comments: 'Comments:',
  comment: 'Comment',
  write: 'Write...',

  //SosForm tab
  insuranceProduct: 'Insurance product',
  attachDocs: 'Attach documents',
  uploadIDCard: 'Photo of identity card',
  IDCardFront: 'Front side of ID Card',
  IDCardBack: 'Back side of ID Card',
  otherDocs: 'Other documents',
  addDocument: 'Add a document',
  iin: 'IIN',
  sex: 'Sex',
  maxDocSize: 'Maximum file size 10mb',
  policyNumber: 'Contract Number',
  //SosNavigation
  sosTitle: 'For which product the insured event occurred?',
  sendRequest: 'Leave a request',

  //Chat section
  askUs: 'Ask',

  //AddCarInfo
  addCarNumber: 'Сar license plate',

  //AddCascoLite
  addCascoBoth: 'Add KASKO light (when both vehicle drivers are to blame )',
  premium: 'Premium',
  insuranceLimit: 'Limit of insurance benefit',
  payAnotherCard: 'Pay with card of another bank',

  //AddCountry
  countryDestination: 'Country of destination',
  addCountry: 'Add country',

  //AddPersonButton
  addInsured: 'Add the Insured',
  removeInsured: 'Remove the Insured',

  //AddPersonInfo
  enterIIN: 'Enter IIN',

  //CheckboxAndSelect
  addCascoLight: 'Add KASKO light',
  cascoLight: 'KASKO light',

  //CheckboxRadioInputList
  selectBonusProduct: 'Select a bonus product',
  additionalProduct: 'Additional product',

  //CreditCardSelect
  addCard: 'Add a card',
  newCard: 'Use new card',
  //FinalAmount
  total: 'Total',

  productRules: 'Insurance rules',

  //SearchSelect
  nothingFound: 'Nothing found',
  loading: 'Loading...',

  //SelectDate
  today: 'Today',
  yesterday: 'Yesterday',

  selectDate: 'Select a date',
  jan: 'January',
  feb: 'February',
  mar: 'March',
  apr: 'April',
  may: 'May',
  jun: 'June',
  jul: 'July',
  aug: 'August',
  sep: 'September',
  oct: 'October',
  nov: 'November',
  dec: 'December',

  janShort: 'Jan',
  febShort: 'Feb',
  marShort: 'Mar',
  aprShort: 'Apr',
  mayShort: 'May',
  junShort: 'Jun',
  julShort: 'Jul',
  augShort: 'Aug',
  sepShort: 'Sep',
  octShort: 'Oct',
  novShort: 'Nov',
  decShort: 'Dec',

  sun: 'Sunday',
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',

  sunShort: 'Sun',
  monShort: 'Mon',
  tueShort: 'Tue',
  wedShort: 'Wed',
  thuShort: 'Thu',
  friShort: 'Fri',
  satShort: 'Sat',

  sunMin: 'Sun',
  monMin: 'Mon',
  tueMin: 'Tue',
  wedMin: 'Wed',
  thuMin: 'Thu',
  friMin: 'Fri',
  satMin: 'Sat',

  //GetInfoCar
  carModel: 'Car model',
  carRegNumber: 'Car license plate',

  //GetInfoPerson
  driverName: 'Driver\'s name',

  //Modals
  terms: 'Terms and conditions',
  understand: 'Understood',

  selectCity: 'Select a city',
  findMyCity: 'Find your city',

  //DocumentsListModal
  docList: 'List of documents',
  notAllDocs: 'Not all the documents can be attached, in this case a request will be considered longer:',
  photoOfID: 'Photo ID',
  photoTechPassport: 'Photo registration document of damaged vehicle',
  photoCourt: 'Photo of the court decision (after the court decision you can report)',
  photoBankDetails: 'Bank account details, IBAN (photo, PDF or text file)',
  ifAggrieved: 'In the presence of aggrieved persons who have suffered harm to life or health, it is necessary to provide additionally:',
  photoSick: 'Photo of sick leave / discharge summary, etc.',
  photoFiscal: 'Photos of fiscal/sales receipts',
  photoDisability: 'Photo of a certificate confirming the establishment of disability',
  photoDeath: 'Photo of death certificate (or notarized copy)',

  //FeedbackResultModal
  lowFeedback: 'Sorry for the low level of service, we will take steps.',
  mediumFeedback: 'Thank you for your feedback, we will get better!',
  highFeedback: 'Thank you for the high evaluation of our work!',

  //SosResultModal
  carInsurance: 'Car Insurance',
  sosRequestSuccess: 'A request has been accepted. Dear Client! We will contact you shortly',

  //OtpVerify
  smsConfirm: 'Enter the code from the SMS message sent to the number',
  nextCode: 'You can get a new code in',
  nextCodeSec: 'sec',
  sendCodeAgain: 'Send code again',

  //CascoLiteForm
  location: 'Location',
  city: 'City',
  street: 'Microdistrict / street',
  building: 'Building',
  apartment: 'Apartment',
  docType: 'Document type',
  docIdConfirm: 'Identity document',
  passportConfirm: 'Паспортные данные, серия и номер',
  docNumber: 'Document number',
  docGiveDate: 'Document issue date',
  kazID: 'ID of the Republic of Kazakhstan',
  passport: 'Passport',
  nonResidentPermit: 'Non-resident residence permit',
  driverLicense: 'Driver License',
  male: 'Male',
  female: 'Female',
  birthCertificate: 'Birth certificate',

  //CovidProtect
  typeOfTrip: 'Type of trip',
  typeOfTripAddition: '* The Insured is entitled to use this policy in case of multiple business trips during the selected period.',
  selectPackage: 'Select a package',
  destinationCountry: 'Country of destination',
  selectInsuranceSum: 'Select the amount of insurance',
  departureDate: 'Departure date',
  arrivalDate: 'Arrival date',
  insurancePeriod: 'Insurance period',
  tripDuration: 'Duration of the trip',

  insuranceCountryWarning: 'At the time of taking out an insurance policy, none of the Insured persons should be in the country of temporary residence.',
  mainPolicyHolder: 'Main Policyholder',
  touristBirthDate: 'Tourist\'s date of birth',
  lastNameInLatin: 'Last name',
  firstNameInLatin: 'First name',
  selectDocument: 'Select a document',
  removeTourist: 'Remove a tourist',
  addTourist: 'Add a tourist',
  infoAbout: 'Information about ',
  ofTourist: ' tourist',

  //CovidProtectSports
  sportType: 'Type of sport',
  isStudentAthlete: 'Is the student an athlete?',
  sports1: 'Baseball, softball, basketball, polo, acrobatics, weightlifting and field-and-track athletics',
  sports2: 'Biathlon, orienteering, fire-applied, shooting, fencing',
  sports3: 'Windsurfing, quad biking, jet skiing',
  sports4: 'Alpine skiing, snowboarding, freestyle, sambo, judo, cycling, wrestling, motoball',
  sports5: 'Diving, spearfishing',
  sports6: 'Kick boxing, boxing, karate, hand-to-hand fighting, taekwondo, wushu, sumo, aikido, kendo, kobudo, kung fu, kudo',
  sports7: 'Parachuting, paragliding, hang gliding, rafting, snowboarding',
  sports8: 'Swimming, synchronized swimming, aerobics, rowing, table tennis, trampoline, kettlebell lifting, gorodki, golf',
  sports9: 'Pentathlon, gymnastics, triathlon, sailing, horseback riding, highboard diving',
  sports10: 'Figure skating, bobsleigh, sleigh, tennis, squash, badminton, speed skating, skiing, water skiing',
  sports11: 'Hockey, iceboating, football, rugby, mountaineering, rock climbing, roller sport, handball, powerlifting, safari',

  alreadyInCountryOfTempResidence: 'Did you already in the country of temporary residence?',
  policyHolderTheInsured: 'The Policyholder is the Insured',

  singleTravel: 'Single',
  multiTravel: 'Multiple',
  resident: 'Resident',
  nonResident: 'Non-resident',
  franchise: 'Franchise',
  //durationsAndDates
  numDays120: '120 days',
  numDays180: '180 days',
  numDays30: '30 days',
  numDays365: '365 days',
  numDays60: '60 days',
  numDays90: '90 days',

  year1: '1 year',
  month1: '1 month',
  month3: '3 months',
  month6: '6 months',
  month12: '12 months',

  upTo180: 'Up to 180',
  upTo45: 'Up to 45',
  upTo90: 'Up to 90',
  upTo30: 'Up to 30',
  upTo10: 'Up to 10',

  order: ['second', 'third', 'fourth', 'fifth'],

  tourist1info: "Information about first tourist",
  tourist2info: "Information about second tourist",
  tourist3info: "Information about third tourist",
  tourist4info: "Information about fourth tourist",
  tourist5info: "Information about fifth tourist",

  //flatOnlineForm
  infoAboutInsuredProperty: 'Information about the insured property',
  country: 'Country',
  region: 'Region',
  district: 'District',
  insuredAndPolicyHolderMatch: 'The data of the Policyholder and the Insured match',

  //OgpoForm
  drivers: 'Drivers',
  addDriver: 'Add a vehicle driver',
  cars: 'Cars',
  addCar: 'Add a car',
  emailNotNecessary: '\'Email\' is not necessary',
  yourAdditionalBonus: 'Your additional bonus',
  driverAlreadyAdded: 'Driver with this IIN is already added',
  carAlreadyAdded: 'Car with this number is already added',
  serverError: 'Server error',
  discount10: '10% Discount',
  autoplusCost: '"Autoplus online" cost',
  productDiscount: 'Product discount',
  bonusSum1: 'Bonus 25%',
  bonusSum2: 'Bonus 22%',
  bonusSum3: 'Bonus 20%',
  bonusSum4: 'Bonus 15%',
  bonusSum11: 'Bonus 30%',

  remainingSum: 'Remaining',

  //TravelerForm
  insuredIsEntitled: 'The Insured is entitled to use this policy in case of multiple business trips during the selected period.',
  insuredSum: 'Insured sum',
  //SendGiftToFriend
  giftFriendInsurance: 'Give a friend the gift of extra insurance!',
  giftFriendNumber: 'Enter your friend\'s mobile phone number, he/she will get a text message with an  "Autoplus online" additional bonus',
  giftFriendSent: 'Dear Customer! An additional insurance policy has been sent to your friend.',
  giftFriendSmsSent: 'A text message with a link to the application was sent to your via sms',
  giftAlreadySent: 'You have already sent a gift',
  alreadyRatedInvoice: 'A review has already been saved for this policy',
  rateSaved: 'Rate saved',
  //SecondaryPageLayout
  backToMain: 'To Home page',
  //PaymentNumberContract
  payContractTitle: 'Pay by contract number',
  payContractPleaceH: 'Number of contract',
  payContractBtn: 'Go to the payment',
  payContractTitle_1: 'Product:',
  payContractTitle_2: 'Policyholder:',
  payContractTitle_3: 'Insured:',
  payContractTitle_4: 'Object of insurance:',
  payContractTitle_5: 'Treaty ',
  payContractTitle_6: 'conclusion of the contract ',
  payCard: 'Pay by card',
  agreedChangeTariff: 'I agree with {0} insurance and tariffs of Halyk Insurance Company JSC. I am familiar with {1} insurance',
  monthlyPayment: 'Ежемесячный платеж',
  paymentDate: 'Payment date',
  paymentDateNew: 'Payment date',
  payUpTo: 'Pay up to',
  сhangeMap: 'Change map',
  cancelSubscription: 'Cancel subscription',
  subscription: 'Subscription',
  rate: 'Rate',
  underProtection: 'Under protection',
  startRate: 'Tariff start date',
  rates: 'Tariffs',
  yourRate: 'Your tariff',
  firstPaymentDate: 'First payment date',
  youReallyUnsubscribe: 'Are you sure you want to cancel your subscription?',
  yourMark: 'Your rating will help us a lot in improving our services.',
  yourCardDebited: '10 ₸ will be deducted from your card, and then returned back to the card',
  good: 'Good',
  cancel: 'Cancel',
  info: 'Info',
  goTariff: 'go',

  canceledSubscription_1: 'Subscription canceled successfully',
  canceledSubscription_2: 'You can renew your subscription in your account',
  paymentCardSuccessfully_1: 'Payment card successfully',
  paymentCardSuccessfully_2: 'Now payments will be debited from this card',
  tariffChanged_1: 'Tariff successfully changed',
  tariffChanged_2: 'The tariff will take effect from ',
  noResultForSelect: 'Sorry, no matching options'
} 